/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { forwardRef, Ref } from 'react'
import { Link, GatsbyLinkProps } from 'gatsby'

type LinkProps = Omit<GatsbyLinkProps<object>, 'as' | 'href' | 'ref' | 'type' | 'to'>
export interface LinkComponentProps extends LinkProps {
    href?: any
    ref?: any
    to?: any
}

const AComponent = forwardRef<HTMLAnchorElement, LinkComponentProps>(
    ({ href, to, ...props }, ref: Ref<HTMLAnchorElement>) => {
        if (!href && !to) return <a ref={ref} {...props} />
        const aPath = to || href
        const hrefAsString = typeof aPath === 'string' ? aPath : aPath?.pathname
        if (hrefAsString && /^(https?:\/\/|\/\/)/i.test(hrefAsString)) {
            return <a ref={ref} href={hrefAsString} {...props} />
        }

        return <Link ref={ref} to={aPath} {...props} />
    }
)

AComponent.displayName = 'AComponent'

export default AComponent