import { createContext, useContext } from 'react'

export interface ThemeProviderProps {
    currentTheme?: string
    setThemeMode: ( string ) => void
}

const ThemeContext = createContext<ThemeProviderProps | null>(null)

export const useThemeContext = () => {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error(
            'ThemeToggle cannot be rendered outside the ThemeProvider component'
        )
    }
    return context
}

export default ThemeContext