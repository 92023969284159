import React, { ReactNode } from 'react'
import { Core } from '@parsley_design_systems/design-system'
import AComponent from '../AComponent/AComponent'
import customTheme from '../../theme/customTheme'
import ThemeProvider from '../ThemeProvider/ThemeProvider'

interface AppProps {
    children: ReactNode
}

const App = ({children} : AppProps) => (
    <ThemeProvider>
        <Core linkComponent={AComponent} customCoreTheme={customTheme}>
            {children}
        </Core>
    </ThemeProvider>
)


export default App